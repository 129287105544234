import React from "react";

import styled from "styled-components";

const IconStyle = styled.svg`
  width: ${props => props.width || "25px"};
  margin: 5px;
  @media only screen and (max-width: 740px) {
    opacity: ${({ enlargeOnMobile }) => enlargeOnMobile && "0.6"};
    width: ${({ enlargeOnMobile }) => enlargeOnMobile && "45px"};
  }
`;

export default IconStyle;
