import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Header from "components/Header";
import Footer from "components/Footer";
import config from "../../data/SiteConfig";

const Container = styled.div`
  margin: 3rem auto;
  max-width: 720px;
  padding: 0 1rem;
`;

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <Helmet>
          <meta name="description" content={config.siteDescription} />
        </Helmet>
        <Header />
        <Container>{children}</Container>
        <Footer config={config} />
      </div>
    );
  }
}
