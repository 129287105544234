import React from "react";
import styled from "styled-components";

import { Github, Twitter } from "components/Icon";
import config from "../../data/SiteConfig";

const FooterStyled = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  padding: 0 1em;
  width: 100%;
  bottom: 0;
`;

const StyledLink = styled.a`
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
  svg {
    fill: #91a7bc;
    &:hover {
      fill: #003040;
    }
  }
`;

const Copyright = styled.span`
  font-size: 0.8rem;
  flex-grow: 1;
  color: #91a7bc;
`;

export default function Footer() {
  const { copyright } = config;
  return <FooterStyled>
      <Copyright>{copyright}</Copyright>
      <StyledLink href="https://github.com/vivekkalyan" target="_blank" rel="noopener" title="Github">
        <Github width="20px" />
      </StyledLink>
      <StyledLink href="https://twitter.com/vivekkalyansk" target="_blank" rel="noopener" title="Twitter">
        <Twitter width="20px" />
      </StyledLink>
    </FooterStyled>;
}
