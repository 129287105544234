import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const HeaderBar = styled.header`
  background: #003040;
  width: 100%;
  padding: 1.2em 0;
`;
const Nav = styled.nav`
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
  li {
    display: inline-block;
    margin-left: 1em;
    margin-bottom: 0;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }
  a {
    box-shadow: none;
    color: #91a7bc;
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color: white;
    }
  }
  h4 {
    margin-top: 0;
  }
`;

const activeLinkStyle = {
  color: "white"
};

const Header = () => {
  return (
    <HeaderBar>
      <Nav>
        <ul>
          <li>
            <h4>
              <Link to="/" activeStyle={activeLinkStyle}>
                Vivek Kalyan
              </Link>
            </h4>
          </li>
          <li>
            <h4>
              <Link to="/about/" activeStyle={activeLinkStyle}>
                About
              </Link>
            </h4>
          </li>
          <li>
            <h4>
              <Link to="/blog/" activeStyle={activeLinkStyle}>
                Blog
              </Link>
            </h4>
          </li>
        </ul>
      </Nav>
    </HeaderBar>
  );
};

export default Header;
